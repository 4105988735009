import { OrderActionTypes } from '../../actions/usersActions/actionType';
import actions from 'redux-form/lib/actions';

export const initialState = {
    isLoading: false,
    orderAllDetails: null,
    isSubmitting: false,
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {


        case OrderActionTypes.FETCH_ORDER_DETAILS_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_ORDER_DETAILS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                orderAllDetails: action.payload && action.payload ? action.payload.order : null
            });

        case OrderActionTypes.ORDER_FEEDBACK_PENDING:
            return updateObject(state, { isLoading: true, isSubmitting: true });
        case OrderActionTypes.ORDER_FEEDBACK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isSubmitting: false
            });
        case OrderActionTypes.ORDER_ALL_DETAILS:
            return updateObject(state, {
                orderAllDetails: action.payload && action.payload.orderAllDetails ? action.payload.orderAllDetails : null
            });
        default: return state;
    }
}