import React from 'react'
import StarRatingComponent from 'react-star-rating-component';
import Oux from '../../../hoc/Oux/Oux'
import color from '@material-ui/core/colors/orange';
import { StarRatingTypes } from '../../../utility/constants/constants';

// ✭ => star icon


// To Apply Gradient Color To Text below three css files

// background:"linear-gradient(to right, #30CFD0 0%, #330867 100%)"
// WebkitBackgroundClip: 'text'
// WebkitTextFillColor: 'transparent'

const starRating = (props) => {
    return (
        <Oux>
            {props.ratingType === StarRatingTypes.customIconStarRating ?
                <div style={{ fontSize: 30 }}>
                    <StarRatingComponent
                        name="customIconStarRate"
                        starCount={props.starCount}
                        value={props.starRate}
                        onStarClick={props.onClickStar}
                        starColor="#FE8500"
                        emptyStarColor="#CCCCCC"
                        onStarHover={props.starHover}
                        onStarHoverOut={props.starHoverOut}
                        renderStarIcon={() => <span style={{ paddingRight: '10px' }}>✭</span>} />
                </div> : null}

            {props.ratingType === StarRatingTypes.defaultIconStarRating ?
                <div style={{ fontSize: 25 }}>
                    <StarRatingComponent
                        name="defaultIconStarRating"
                        starCount={props.starCount}
                        value={props.starRate}
                        onStarClick={props.onClickStar}
                        starColor="#f00"
                        onStarHover={props.starHover}
                        onStarHoverOut={props.starHoverOut} />
                </div> : null}

            {props.ratingType === StarRatingTypes.nonRatableStarRating ?
                <div style={{ fontSize: props.starSize }}>
                    <StarRatingComponent
                        name="nonRatableStarRating"
                        starCount={props.starCount}
                        value={props.starRate}
                        renderStarIcon={() => <span><i className="fas fa-star" /></span>}
                        starColor="#f00" />
                </div> : null}

            {props.ratingType === StarRatingTypes.halfStarRating ?
                <div style={{ fontSize: 24 }}>
                    <StarRatingComponent
                        name="halfIconStarRate"
                        starCount={props.starCount}
                        value={props.starRate}
                        onStarClick={props.onClickHalfStar}
                        onStarHover={props.halfStarHover}
                        onStarHoverOut={props.starHoverOut}
                        starColor="#16449A"
                        emptyStarColor="#16449A"
                        renderStarIcon={(index, value) => {
                            return (
                                <span>
                                    <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                                </span>
                            );
                        }}
                        renderStarIconHalf={() => {
                            return (
                                <span>
                                    <span style={{ position: 'absolute' }}><i className="far fa-star" /></span>
                                    <span><i className="fas fa-star-half" /></span>
                                </span>
                            );
                        }} />
                </div> : null}
            {props.ratingType === StarRatingTypes.halfStarNonRatable ?
                <div style={{ fontSize: 24 }}>
                    <StarRatingComponent
                        name="halfStarNonRatable"
                        starCount={props.starCount}
                        value={props.starRate}
                        editing='false'
                        starColor="#f00"
                        renderStarIcon={(index, value) => {
                            return (
                                <span>
                                    <i className={index <= value ? 'fas fa-star' : 'far fa-star'} style={{ color: '#f00' }} />
                                </span>
                            );
                        }}
                        renderStarIconHalf={() => {
                            return (
                                <span>
                                    <span style={{ position: 'absolute', color: '#f00' }}><i className="far fa-star" /></span>
                                    <span style={{ color: '#f00' }}><i className="fas fa-star-half" /></span>
                                </span>
                            );
                        }} />
                </div> : null}
        </Oux>
    );
}

export default starRating