import { combineReducers } from 'redux';
import { historyReducer } from './users/historyReducer'
import { orderReducer } from './users/orderReducer'

const reducers = combineReducers({
        historyReducer,
        orderReducer,
});

export default reducers;
