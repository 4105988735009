export const OrderActionTypes = {

    ORDER_FEEDBACK: 'ORDER_FEEDBACK',
    ORDER_FEEDBACK_PENDING: 'ORDER_FEEDBACK_PENDING',
    ORDER_FEEDBACK_FULFILLED: 'ORDER_FEEDBACK_FULFILLED',

    FETCH_ORDER_DETAILS: 'FETCH_ORDER_DETAILS',
    FETCH_ORDER_DETAILS_PENDING: 'FETCH_ORDER_DETAILS_PENDING',
    FETCH_ORDER_DETAILS_FULFILLED: 'FETCH_ORDER_DETAILS_FULFILLED',

    ORDER_ALL_DETAILS: "ORDER_ALL_DETAILS",
    FETCH_QUICK_SALE_ORDER:"FETCH_QUICK_SALE_ORDER",
    QUICK_SALE_ORDER_FEEDBACK:"QUICK_SALE_ORDER_FEEDBACK"
}

export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY",
    IS_CMS: 'IS_CMS'
}

