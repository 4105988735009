/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import {
  formInputErrorStyle,
  formInputTextErrorStyle,
  routes,
  StarRatingTypes,
} from "../../utility/constants/constants";
import jwt from "jsonwebtoken";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../redux/actions/index";
import SpinnerLoader from "../../components/UI/SpinnerLoader/SpinnerLoader";
import Oux from "../../hoc/Oux/Oux";

import StarRating from "../../components/UI/StarRating/StarRating";
import { defaultImagePath } from "../../utility/utility";
import LoadingBtn from "../../components/UI/LoadingButton/LoadingButton";
import { fetchQuickSaleOrder, quickSaleOrderFeedback } from "../../redux/actions/usersActions/ordersAction";

let hoverStarRating = 0;

const Feedback = (props) => {
  const url = 'https://g.page/r/CaWHFprk9pYpEBM/review'
  const newrelic = window.newrelic;
  const token = props.match.params;
  console.log("props.match.params", props.match.params);
  const [starRating, setStarRating] = useState(0);
  const [isOnClickTrue, setIsOnClickTrue] = useState(false);
  const [googleRedirect, setGoogleRedirect] = useState(false);
  const [branchID, setBranchId] = useState();
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const didMountRef = useRef();
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const onStarClick = (nextValue, prevValue, name) => {
    setStarRating(nextValue);
    setIsOnClickTrue(true);
    hoverStarRating = nextValue;
    console.log(
      "name: %s, nextValue: %s, prevValue: %s",
      name,
      nextValue,
      prevValue
    );
  };

  const onHoverStar = (nextValue, prevValue, name) => {
    console.log(nextValue);
    setStarRating(nextValue);
  };

  const onHoverStarOut = (nextValue, prevValue, name) => {
    if (isOnClickTrue) {
      setStarRating(hoverStarRating);
    } else {
      setStarRating(0);
    }
  };

  const onChangeCommentField = (event) => {
    setComment(event.target.value);
    if (event.target.value && event.target.value.length > 1000) {
      setCommentError("Max 1000 characters");
    } else {
      setCommentError("");
    }
  };

  const onSubmitFeedback = () => {
    newrelic.interaction().save();
    console.log(starRating, "<<<<<<<StartRate", comment, "<<<<<<Comment");
    // let token = new URLSearchParams(props.history.location.search, {6
    //   ignoreQueryPrefix: true,
    // }).get("token");

    // const token = jwt.sign(props.match.params, "secret");
    const token = props.match.params;
    // let decryptedToken = jwt.decode(token);
    newrelic.interaction().setAttribute("Events", "Submit Click");
    newrelic.interaction().setAttribute("Order Number", token.OrderNumber);
    newrelic.interaction().setAttribute("Token Payload", token);
    newrelic.interaction().setAttribute("Rating", starRating);
    newrelic.interaction().setAttribute("Comment", comment);
    newrelic.interaction().setAttribute("Request Payload", {
      feedback: { Comment: comment, Rating: starRating },
    });
    newrelic.interaction().setAttribute("Response Payload", "-");
    newrelic.interaction().save();
    if (["outbound_delivery", "outbound_sale"].includes(token.type)) {
      let google_redirect = false;
      if ([4, 5].includes(starRating) && token.BranchID == "37" && googleRedirect) {

        google_redirect = true
      }
      const feedback = {
        feedback: { Comment: comment, Rating: starRating, google_redirect },
        DeliveryOrderID: token.orderID,
        type: token.type,
      };
      props
        .outbounDeliveryFeedback(feedback)
        .then((response) => {
          // newrelic

          // end  - newrelic
          if (response.value.success) {


            setTimeout(() => {
              newrelic.interaction().setAttribute("Events", "Page Submit");
              newrelic
                .interaction()
                .setAttribute("Order Number", token.OrderNumber);
              newrelic.interaction().setAttribute("Token Payload", token);
              newrelic.interaction().setAttribute("Rating", starRating);
              newrelic.interaction().setAttribute("Comment", comment);
              newrelic.interaction().setAttribute("Request Payload", {
                feedback: { Comment: comment, Rating: starRating },
              });
              newrelic
                .interaction()
                .setAttribute("Response Payload", "Thank you");
              newrelic.interaction().save();
              console.log("Page Submit NewRelic Event Triggered");
            }, [1000]);
            if ([4, 5].includes(starRating) && token.BranchID === "37" && googleRedirect) {
              window.location.href = url;
            } else {

              props.history.push(routes.THANK_YOU, {
                isFeedbackSubmitted: true,
              });
            }
          } else {
            setTimeout(() => {
              newrelic.interaction().setAttribute("Events", "Page Submit");
              newrelic
                .interaction()
                .setAttribute("Order Number", token.OrderNumber);
              newrelic.interaction().setAttribute("Token Payload", token);
              newrelic.interaction().setAttribute("Rating", starRating);
              newrelic.interaction().setAttribute("Comment", comment);
              newrelic.interaction().setAttribute("Request Payload", {
                feedback: { Comment: comment, Rating: starRating },
              });
              newrelic
                .interaction()
                .setAttribute(
                  "Response Payload",
                  response.action.payload.message
                );
              newrelic.interaction().save();
              console.log("Page Submit NewRelic Event Triggered");
            }, [1000]);
          }
        })
        .catch((error) => {
          newrelic.interaction().setAttribute("Events", "Submit Catch");
          newrelic
            .interaction()
            .setAttribute("Order Number", token.OrderNumber);
          newrelic.interaction().setAttribute("Token Payload", token);
          newrelic.interaction().setAttribute("Rating", starRating);
          newrelic.interaction().setAttribute("Comment", comment);
          newrelic.interaction().setAttribute("Request Payload", {
            feedback: { Comment: comment, Rating: starRating },
          });
          newrelic.interaction().setAttribute("Response Payload", error);
          newrelic.interaction().save();
          console.log("Page Submit NewRelic Event Triggered");
          console.log(error);
        });
    }
    else if (["quicksale"].includes(token.type)) {
      setShowLoader(true)
      let google_redirect = false;
      if ([4, 5].includes(starRating) && branchID === 37 && googleRedirect) {
        google_redirect = true
      }
      const feedback = {
        feedback: { Comment: comment, Rating: starRating, google_redirect }, OrderNumber: token.OrderNumber
      };
      dispatch(quickSaleOrderFeedback(feedback)).then((response) => {
        if (response?.value?.success) {
          setTimeout(() => {
            newrelic.interaction().setAttribute("Events", "Page Submit");
            newrelic
              .interaction()
              .setAttribute("Order Number", token.OrderNumber);
            newrelic.interaction().setAttribute("Token Payload", token);
            newrelic.interaction().setAttribute("Rating", starRating);
            newrelic.interaction().setAttribute("Comment", comment);
            newrelic.interaction().setAttribute("Request Payload", {
              feedback: { Comment: comment, Rating: starRating },
            });
            newrelic
              .interaction()
              .setAttribute("Response Payload", "Thank you");
            newrelic.interaction().save();
            console.log("Page Submit NewRelic Event Triggered");
          }, [1000]);
          setShowLoader(false)
          if ([4, 5].includes(starRating) && branchID == 37 && googleRedirect) {
            window.location.href = url;
          }
          else { props.history.push(routes.THANK_YOU, { isFeedbackSubmitted: true }) };
        } else {
          setTimeout(() => {
            newrelic.interaction().setAttribute("Events", "Page Submit");
            newrelic
              .interaction()
              .setAttribute("Order Number", token.OrderNumber);
            newrelic.interaction().setAttribute("Token Payload", token);
            newrelic.interaction().setAttribute("Rating", starRating);
            newrelic.interaction().setAttribute("Comment", comment);
            newrelic.interaction().setAttribute("Request Payload", {
              feedback: { Comment: comment, Rating: starRating },
            });
            newrelic
              .interaction()
              .setAttribute(
                "Response Payload",
                response.action.payload.message
              );
            newrelic.interaction().save();
            console.log("Page Submit NewRelic Event Triggered");
          }, [1000]);
        }

      })
    } else {
      let google_redirect = false;
      if ([4, 5].includes(starRating) && token.BranchID == "37" && googleRedirect === true) {
        google_redirect = true
      }
      const feedback = {
        feedback: { Comment: comment, Rating: starRating, google_redirect },
        OrderNumber: token.OrderNumber,
        type: token.type,

      };
      props
        .orderFeedback(feedback)
        .then((response) => {
          // if([4,5].includes(starRating) && token.BranchID === "37" && googleRedirect===true){
          //   window.location.href = url;
          // }
          if (response.value.success) {
            setTimeout(() => {
              newrelic.interaction().setAttribute("Events", "Page Submit");
              newrelic
                .interaction()
                .setAttribute("Order Number", token.OrderNumber);
              newrelic.interaction().setAttribute("Token Payload", token);
              newrelic.interaction().setAttribute("Rating", starRating);
              newrelic.interaction().setAttribute("Comment", comment);
              newrelic.interaction().setAttribute("Request Payload", {
                feedback: { Comment: comment, Rating: starRating },
              });
              newrelic
                .interaction()
                .setAttribute("Response Payload", "Thank you");
              newrelic.interaction().save();
              console.log("Page Submit NewRelic Event Triggered");
            }, [1000]);
            if ([4, 5].includes(starRating) && token.BranchID == "37" && googleRedirect) {
              window.location.href = url;
            }
            else {
              props.history.push(routes.THANK_YOU, { isFeedbackSubmitted: true })
            };
          } else {
            setTimeout(() => {
              newrelic.interaction().setAttribute("Events", "Page Submit");
              newrelic
                .interaction()
                .setAttribute("Order Number", token.OrderNumber);
              newrelic.interaction().setAttribute("Token Payload", token);
              newrelic.interaction().setAttribute("Rating", starRating);
              newrelic.interaction().setAttribute("Comment", comment);
              newrelic.interaction().setAttribute("Request Payload", {
                feedback: { Comment: comment, Rating: starRating },
              });
              newrelic
                .interaction()
                .setAttribute(
                  "Response Payload",
                  response.action.payload.message
                );
              newrelic.interaction().save();
              console.log("Page Submit NewRelic Event Triggered");
            }, [1000]);
          }
        })
        .catch((error) => {
          newrelic.interaction().setAttribute("Events", "Submit Catch");
          newrelic
            .interaction()
            .setAttribute("Order Number", token.OrderNumber);
          newrelic.interaction().setAttribute("Token Payload", token);
          newrelic.interaction().setAttribute("Rating", starRating);
          newrelic.interaction().setAttribute("Comment", comment);
          newrelic.interaction().setAttribute("Request Payload", {
            feedback: { Comment: comment, Rating: starRating },
          });
          newrelic.interaction().setAttribute("Response Payload", error);
          newrelic.interaction().save();
          console.log("Page Submit NewRelic Event Triggered");
          console.log(error);
        });
    }
  };

  useEffect(() => {
    newrelic.interaction().setAttribute("Events", "Page Load");
    newrelic.interaction().setAttribute("Order Number", token.OrderNumber);
    newrelic.interaction().setAttribute("Token Payload", token);

    newrelic.interaction().setAttribute("Request Payload", "-");
    newrelic.interaction().setAttribute("Response Payload", "-");
    if (!didMountRef.current) {
      didMountRef.current = true;

      const token = props.match.params;
      let orderNumber = null;
      if (token) {
        orderNumber = token.OrderNumber;

        if (!orderNumber) {
          props.history.push(routes.PAGE_NOT_FOUND);
          newrelic.setAttribute(
            "Page Not Loaded",
            orderNumber ? orderNumber : props.history.location.search
          );
        } else {
          if (["outbound_delivery", "outbound_sale"].includes(token.type.toLowerCase())) {
            props
              .fetchOutboundOrderDetails(token.orderID)
              .then((response) => {
                if (
                  response.value.success &&
                  response.value.order &&
                  response.value.order.feedback &&
                  response.value.order.feedback.length > 0
                ) {
                  setGoogleRedirect(response?.action?.payload?.order?.google_redirect)
                  response.value.order.feedback.map((item) => {
                    if (item.Type === token.type && item.Rating) {
                      props.history.push(routes.THANK_YOU, {
                        isFeedbackSubmitted: true,
                      });
                    }
                  });
                } else {
                  props.history.push(routes.PAGE_NOT_FOUND);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (["quicksale"].includes(token.type.toLowerCase())) {
            dispatch(fetchQuickSaleOrder(token.OrderNumber)).then(response => {

              if (
                response.value.success &&
                response.value.order &&
                response.value.order.feedback &&
                response.value.order.feedback.length > 0
              ) {
                setGoogleRedirect(response?.action?.payload?.order?.google_redirect)
                setBranchId(response?.value?.order?.BranchID)
                response.value.order.feedback.map((item) => {
                  if (item.Rating) {
                    props.history.push(routes.THANK_YOU, {
                      isFeedbackSubmitted: true,
                    });
                  }
                });
              } else {
                props.history.push(routes.PAGE_NOT_FOUND);
              }

            }).catch((error) => {

            })
          }

          else {
            props
              .fetchOrderDetails(orderNumber)
              .then((response) => {
                console.log('response', response);
                if (
                  response.value.success &&
                  response.value.order &&
                  response.value.order.feedback &&
                  response.value.order.feedback.length > 0
                ) {
                  setGoogleRedirect(response?.action?.payload?.order?.google_redirect)
                  response.value.order.feedback.map((item) => {
                    if (item.Type === token.type && item.Rating) {
                      props.history.push(routes.THANK_YOU, {
                        isFeedbackSubmitted: true,
                      });
                    }
                  });
                } else {
                  props.history.push(routes.PAGE_NOT_FOUND);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      } else {
        props.history.push(routes.PAGE_NOT_FOUND);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.pathname, props.orderAllDetails]);

  let OrderNumber = null;
  if (token && token.OrderNumber) {
    OrderNumber = token.OrderNumber;
  }

  const messageTitle = () => {
    if (token && token.type.toLowerCase() === "sale".toLowerCase()) {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>
              Thank you for placing your order with {props.isCMS ? "CMS" : "IMS"}
              .
            </b>
          </h4>
          {/* <p className="ft_Weight_500 mb_10">We appreciate feedback on the experience of placing your order today.</p> */}
          {/* <p className="mb-0">IMS, San Diego</p> */}
        </Oux>
      );
    } else if (
      token &&
      ["outbound_delivery", "outbound_sale"].includes(token.type.toLowerCase())
    ) {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>Thank you for using {props.isCMS ? "CMS" : "IMS"}.</b>
          </h4>

        </Oux>
      );
    } else if (token && ["quicksale"].includes(token.type.toLowerCase())) {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>Thank you for using {props.isCMS ? "CMS" : "IMS"}.</b>
          </h4>

        </Oux>
      );
    } else {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>
              Thank you for using the will call text service at{" "}
              {props.isCMS ? "CMS" : "IMS"}.
            </b>
          </h4>

        </Oux>
      );
    }
  };

  const messageContent = () => {
    if (token && token.type.toLowerCase() === "sale".toLowerCase()) {
      return (
        <Oux>
          <p className="ft_Weight_500 mb_10">
            How would you rate your buying experience?
          </p>
          {/* <p className="mb-0">IMS, San Diego</p> */}
        </Oux>
      );
    } else if (
      token &&
      ["outbound_sale"].includes(token.type)
    ) {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>How would you rate your buying experience?</b>
          </h4>
          {/* <p className="ft_Weight_500 mb_10">We appreciate feedback on your will call experience with IMS.</p> */}
          {/* <p className="mb-0">IMS, San Diego</p> */}
        </Oux>
      );
    } else if (token &&
      ["outbound_delivery"].includes(token.type)) {
        return (
          <Oux>
            <h4 className="ft_Weight_600 mb_10">
              <b>How would you rate the Delivery of your metal?</b>
            </h4>
          </Oux>
        );
    } else if (token && ["quicksale"].includes(token.type)) {
      return (
        <Oux>
          <h4 className="ft_Weight_600 mb_10">
            <b>How would you rate the purchase of your metal?</b>
          </h4>
          {/* <p className="ft_Weight_500 mb_10">We appreciate feedback on your will call experience with IMS.</p> */}
          {/* <p className="mb-0">IMS, San Diego</p> */}
        </Oux>
      );
    } else {
      return (
        <Oux>
          {/* <h4 className="ft_Weight_600 mb_10">
                                        <strong>Thank you for your IMS order.</strong>
                                    </h4> */}
          <p className="ft_Weight_500 mb_10">
            How would you rate your will call pickup experience?
          </p>
          {/* <p className="mb-0">IMS, San Diego</p> */}
        </Oux>
      );
    }
  };
  return (
    <Oux>
      {props.isLoading && !props.isSubmitting ? (
        <section className="mpv_body_warpe">
          <div className="mpv_con">
            <SpinnerLoader />
          </div>
        </section>
      ) : (
        <section
          className="home_hero"
          style={
            props.isCMS
              ? { backgroundColor: "#fff" }
              : {
                backgroundImage: `url(${defaultImagePath(
                  "background/hero.png"
                )})`,
              }
          }
        >
          <div className="raitng_wrapper">
            <div className="card ims_card">
              <div className="card-header align-items-center">
                {messageTitle()}
              </div>
              <div className="card-body">
                <article className="ims_art mb_15">{messageContent()}</article>
                <div className="form_group_modify mb-0">
                  <label htmlFor="rateing" className="label_modify">
                    Your rating
                  </label>
                  <div className="starWrpper mb-0">
                    <StarRating
                      starCount={5}
                      ratingType={StarRatingTypes.customIconStarRating}
                      starRate={starRating}
                      onClickStar={onStarClick}
                      starHover={onHoverStar}
                      starHoverOut={onHoverStarOut}
                    />
                  </div>
                </div>
                <div className="form_group_modify">
                  <textarea
                    id="feedback"
                    name="feedback"
                    className="input_modify"
                    style={commentError ? formInputErrorStyle : null}
                    onChange={onChangeCommentField}
                    rows="6"
                    placeholder={`Write your review here (max 1000 chars)`}
                  ></textarea>
                  <span style={formInputTextErrorStyle}>{commentError}</span>
                </div>
              </div>
              <div className="card-footer text-center">
                {props.isSubmitting || showLoader ? (
                  <LoadingBtn
                    btnClassName="theme_btn theme_tertiary theme_btn_rds30 mnw_250"
                    btnTitle="Loading"
                  />
                ) : (
                  <button
                    disabled={starRating === 0 || commentError}
                    onClick={onSubmitFeedback}
                    className={
                      starRating === 0 || commentError
                        ? "theme_btn theme_tertiary theme_btn_rds30 mnw_250 disabled_btn"
                        : "theme_btn theme_tertiary theme_btn_rds30 mnw_250"
                    }
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Oux>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.orderReducer.isLoading,
    orderAllDetails: state.orderReducer.orderAllDetails,
    isSubmitting: state.orderReducer.isSubmitting,
    isCMS: state.historyReducer.isCMS,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderFeedback: (token, feedback) =>
      dispatch(actions.orderFeedback(token, feedback)),
    outbounDeliveryFeedback: (token, feedback) =>
      dispatch(actions.outbounDeliveryFeedback(token, feedback)),
    fetchOrderDetails: (orderNumber) =>
      dispatch(actions.fetchOrderDetails(orderNumber)),
    fetchOutboundOrderDetails: (orderNumber) =>
      dispatch(actions.fetchOutboundOrderDetails(orderNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
