import React, { useEffect } from 'react'
import { routes } from '../../utility/constants/constants'
import { defaultImagePath } from '../../utility/utility'

const ThankYou = props => {

    useEffect(() => {
        if (!props.history.location.state || (props.history.location.state && !props.history.location.state.isFeedbackSubmitted)) {
            props.history.push(routes.PAGE_NOT_FOUND)
        }
    }, [])

    return (
        <section class="theme_nav_mt order_details_sec">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 col-md-8 col-lg-5 mx-auto">
                        <div class="card thankyouCard box_shadow">
                            <div class="img_top_wrapper">
                                <div class="img_wrp">
                                    <img
                                        class="card-img-top"
                                        src={defaultImagePath('icons/like.png')}
                                        alt="Like Icons"
                                    />
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="star_img mb_15">
                                    <img src={defaultImagePath('icons/star.png')} alt="Star Icons" />
                                </div>
                                <h2>Thank You</h2>
                                <p class="card-text">
                                    Thank you for your valuable feedback it helps us in serving you better.
                      </p>
                            </div>
                        </div>
                        <div class="user_details_list_wrp"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ThankYou