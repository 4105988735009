import React, { Component, useState, useEffect } from 'react';
import Aux from '../Oux/Oux';
import {
    routes,
    UserRoles,
    ViewOrderLimitPerPage,
    OrderStatus,
} from '../../utility/constants/constants';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { defaultImagePath } from '../../utility/utility';
import * as actions from '../../redux/actions/index';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Header = props => {
    let logo = 'thumbnails/logo.png';
    if (props.isCMS) {
        logo = 'thumbnails/cms_logo.jpg';
    }
    let headerContent = (
        <header className="main_header">
            <nav className="theme_navigation -closed navbar navbar-expand-lg">
                <a onClick={props.onClickLogo} className="logo">
                    <img
                        src={defaultImagePath(logo)}
                        alt="BitCot Logo"
                        className="header__logo"
                    />
                </a>
            </nav>
        </header>
    );
    return headerContent;
};

const Footer = props => {
    let currentYear = new Date().getFullYear().toString()
    return (
        <footer className="main_footer wow fadeIn">
            <div className="link_list_group">
                <span className="ph_textcopy footer_link">
                    {currentYear}{' '}
                    {props.isCMS
                        ? 'Campbell Metal Supply Company'
                        : 'Industrial Metal Supply Company'}{' '}
          - V 2.0
        </span>
            </div>
        </footer>
    );
};

const Layout = props => {


    useEffect(() => {
        if (props.isCMS) {
            const favicon = document.getElementById('favicon')
            favicon.href = window.location.hostname === 'localhost' ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/cms_favicon.png?v=2` : `${window.location.protocol}//${window.location.hostname}/cms_favicon.png?v=2`
        }
    }, [props.isCMS])

    const onClickLogo = () => {
        props.history.push(routes.ROOT);
    };
    console.log(`${window.location.protocol}//${window.location.hostname}/cms_favicon.png?v=2`)
    return (
        <Aux>
            {props.isCMS ? (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>CMS</title>
                </Helmet>
            ) : null}
            <div className="limiter">
                <div className="container-login100">
                    <Header onClickLogo={onClickLogo} {...props} />
                    <div id="wrap">
                        {/* {props.isSocketDisconnected ? <Sub_header /> : null} */}
                        {props.children}
                    </div>
                    <Footer {...props} />
                </div>
            </div>
        </Aux>
    );
};

const mapStateToProps = state => {
    return {
        isLoading: state.orderReducer.isLoading,
        isCMS: state.historyReducer.isCMS,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        orderFeedback: (rating, token, comment) =>
            dispatch(actions.orderFeedback(rating, token, comment)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
