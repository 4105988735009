import React from 'react'
import { defaultImagePath } from '../../utility/utility'
import { connect } from 'react-redux';

const Error404 = props => {
    return (
        <section
            class="home_hero"
            style={props.isCMS ? { backgroundColor: '#fff' } : { backgroundImage: `url(${defaultImagePath('background/hero.png')})` }}>
            <div class="error_page">
                <article>
                    <h1 data-h1="404">4<span class="cstm-text-black">0</span>4</h1>
                    <p data-p="NOT FOUND">NOT FOUND</p>
                    <p class="cstm-bottom-text">
                        The requested URL was not found on this server.
                </p>
                    {/* <a
                        href="javascript:void(0)"
                        class="theme_btn theme_tertiary theme_btn_rds30 mnw_250"
                        data-dismiss="modal">Go to home</a> */}
                </article>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        isCMS: state.historyReducer.isCMS
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404)