import React, { useEffect } from "react";
import "./App.css";
import AppRouter from "./router/router";
import { removeConsoleLog } from "./utility/utility";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RouteChangeListener from "./utility/RouteChangeListener";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import * as miscActions from "./redux/actions/usersActions/miscAction";
import Oux from "./hoc/Oux/Oux";
import { useRef } from "react";
import jwt from "jsonwebtoken";
import { branches } from "../src/utility/Branch/Branches";

const App = (props) => {
  const didMountRef = useRef(false);
  let pathname = props.location.pathname;
  let splitpathnames = pathname.split("/")[2];
  const branch = branches.find((val) => val.BranchID == splitpathnames);

  useEffect(() => {
    if (didMountRef.current) {
      //didUpdate
    } else {
      didMountRef.current = true;
      if (process.env.REACT_APP_ENV === "production") {
        removeConsoleLog();
      }

      if (branch && branch.Company === "CMS") {
        props.setIsCMS(true);
      } else {
        props.setIsCMS(false);
      }
      props.addHistory(props.history);
      // socket.on('user_connection', (msg) => {
      //   console.log(msg, "socketMsg")
      // })
    }
  }, []);

  return (
    <Oux>
      <RouteChangeListener />
      <ReactNotification />
      <AppRouter {...props} />
    </Oux>
  );
};

const mapStateToProps = (state) => ({});

const mapStateToDispatch = (dispatch) => ({
  addHistory: (history) => dispatch(miscActions.addHistory(history)),
  setIsCMS: (value) => dispatch(miscActions.setIsCMS(value)),
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(App));
