import axios from "./../config";

export const fetchOrderDetails = (orderNumber) => axios.get(`v1/orders/detail/${orderNumber}`)

export const orderFeedback = (feedback) => axios.post(`v1/survey/feedback`, feedback);

export const outbounDeliveryFeedback = (feedback) => axios.post(`v1/survey/feedback_delivery_orders`, feedback);

export const fetchOutboundOrderDetails = (orderNumber) => axios.get(`/v1/orders/delivery_detail/${orderNumber}`);


export const fetchQuickSaleOrder = (orderNumber) => axios.get(`v1/orders/quicksale_detail/${orderNumber}`);

export const feedbackQuickSaleOrder = (params) => axios.post(`v1/survey/feedback_quick_sale`, params);