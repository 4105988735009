import { toastMsg } from "../utility";

const tokenInterceptor = (response) => {
  if (response.data.error) {
    // toastMsg(response.data.error.message, true, 5000);
  }

  if (response.data.code) {
    // toastMsg(response.data.message,true, 5000);
  }
  return response;
};

export default tokenInterceptor;
