/* eslint-disable eqeqeq */
import { OrderActionTypes } from './actionType';
import * as API from '../../../api/ordersAPI';


export const orderSummeryDetails = (orderAllDetails) => dispatch => dispatch({
    type: OrderActionTypes.ORDER_ALL_DETAILS,
    payload: {
        orderAllDetails: orderAllDetails
    }
});

export const fetchOrderDetails = (orderNumber) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_DETAILS,
    payload: API.fetchOrderDetails(orderNumber)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const fetchOutboundOrderDetails = (orderNumber) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_DETAILS,
    payload: API.fetchOutboundOrderDetails(orderNumber)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const orderFeedback = (token, feedback) => dispatch => dispatch({
    type: OrderActionTypes.ORDER_FEEDBACK,
    payload: API.orderFeedback(token, feedback)
        .then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            return error;
        })
});

export const outbounDeliveryFeedback = (token, feedback) => dispatch => dispatch({
    type: OrderActionTypes.ORDER_FEEDBACK,
    payload: API.outbounDeliveryFeedback(token, feedback)
        .then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            return error;
        })
});


export const fetchQuickSaleOrder = (orderNumber)=>dispatch=> {
   return dispatch({
        type:OrderActionTypes.FETCH_QUICK_SALE_ORDER,
        payload:API.fetchQuickSaleOrder(orderNumber).then(response => {
            if (response.data.success || response.data.success == "true") {
                return response.data;
            } else {
                return response.data;
            }
        }).catch(error => {
            console.log(error);
            return error;
        })
    })
}
export const quickSaleOrderFeedback =  (params)=>  (dispatch)=> {
    return dispatch({
        type:OrderActionTypes.QUICK_SALE_ORDER_FEEDBACK,
        payload:  API.feedbackQuickSaleOrder(params).then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            debugger
            console.log(error);
            return error;
        })
    })
}
