export const branches = [
  {
    BranchID: 14,
    mssql_branchid: null,
    BranchCode: "11",
    Name: "Export",
    Address: "Export",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-08-11T07:57:55.990Z",
    ChatbotPhone: "",
    FromPhoneNumber: "",
    Phone: "818-729-3378",
    SurveyPhoneNumber: "",
    Company: "IMS",
    id: 4,
  },
  {
    BranchID: 18,
    mssql_branchid: null,
    BranchCode: "99",
    Name: "Offsite Storage",
    Address: "Offsite Storage",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-04-02T10:28:03.661Z",
    ChatbotPhone: "",
    FromPhoneNumber: "",
    Phone: "",
    SurveyPhoneNumber: null,
    Company: "IMS",
    id: 5,
  },
  {
    BranchID: 1,
    mssql_branchid: null,
    BranchCode: "02",
    Name: "Irvine",
    Address: "https://location.imsmetals.com/irvine",
    created_at: "2021-04-02T10:28:03.660Z",
    updated_at: "2021-11-12T15:29:31.985Z",
    ChatbotPhone: "+18043616836",
    FromPhoneNumber: "+13237458306",
    Phone: "949-266-3217",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 1,
  },
  {
    BranchID: 6,
    mssql_branchid: null,
    BranchCode: "01",
    Name: "Sun Valley",
    Address: "https://location.imsmetals.com/sunvalley",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2022-01-19T12:48:19.054Z",
    ChatbotPhone: "+13237458306",
    FromPhoneNumber: "+13237458306",
    Phone: "818-729-3378",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 2,
  },
  {
    BranchID: 10,
    mssql_branchid: null,
    BranchCode: "06",
    Name: "Tucson",
    Address: "https://location.imsmetals.com/tucson",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-12-07T12:08:48.457Z",
    ChatbotPhone: "+18043616836",
    FromPhoneNumber: "+13237458306",
    Phone: "520-441-5907",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 3,
  },
  {
    BranchID: 26,
    mssql_branchid: null,
    BranchCode: "03",
    Name: "San Diego",
    Address: "https://location.imsmetals.com/sandiego",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-11-10T15:43:41.140Z",
    ChatbotPhone: "+18043616836",
    FromPhoneNumber: "+13237458306",
    Phone: "858-769-3478",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 6,
  },
  {
    BranchID: 27,
    mssql_branchid: null,
    BranchCode: "04",
    Name: "Phoenix",
    Address: "https://location.imsmetals.com/phoenix",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-12-02T12:04:32.289Z",
    ChatbotPhone: "+18043732647",
    FromPhoneNumber: "+13237458306",
    Phone: "602-218-3945",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 7,
  },
  {
    BranchID: 28,
    mssql_branchid: null,
    BranchCode: "05",
    Name: "Riverside",
    Address: "https://location.imsmetals.com/riverside",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-11-27T16:50:28.176Z",
    ChatbotPhone: "+18043616836",
    FromPhoneNumber: "+13237458306",
    Phone: "951-300-9907",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 8,
  },
  {
    BranchID: 37,
    mssql_branchid: null,
    BranchCode: "07",
    Name: "San Jose",
    Address: "https://location.imsmetals.com/sanjose",
    created_at: "2021-04-02T10:28:03.661Z",
    updated_at: "2021-10-22T05:33:12.224Z",
    ChatbotPhone: "+14083510859",
    FromPhoneNumber: "+13237458306",
    Phone: "408-432-4890",
    SurveyPhoneNumber: "+18185383906",
    Company: "IMS",
    id: 9,
  },
];
