import React from 'react';
import Layout from '../hoc/Layout/Layout'
import { Route, Switch, Redirect } from 'react-router-dom'
import { routes, UserRoles } from '../utility/constants/constants';
import Feedback from '../containers/Feedback/Feedback';
import Error404 from '../components/404/404';
import ThankYou from '../containers/ThankYou/ThankYou';


const Router = props => {
    let routeList = null;

    routeList = (
        <Switch>
            <Route exact path={routes.ROOT} render={() => <Redirect to={routes.PAGE_NOT_FOUND} />} />
            <Route exact path={routes.FEEDBACK} component={Feedback} />
            <Route exact path={routes.THANK_YOU} component={ThankYou} />
            <Route exact path={routes.PAGE_NOT_FOUND} component={Error404} />
            <Route path='*' render={(props) => <Redirect to={routes.ROOT} />} />
        </Switch>
    )

    return (
        <Layout>
            {routeList}
        </Layout>
    )
}

export default Router

export const NotFound = () => {
    return (
        <h1 className="text-center" style={{ margin: '100px' }}>404. Page not found.</h1>
    );
};