import axios from "axios";

const BASE_URL = () => {
  let url;
  // https://stage-willcall-api.imsmetals.com/v1
  if (process.env.REACT_APP_ENV === "development") {
    url = "https://stage-willcall-api.imsmetals.com";
  }

  if (process.env.REACT_APP_ENV === "staging") {
    url = "https://stage-willcall-api.imsmetals.com";
  }

  if (process.env.REACT_APP_ENV === "production") {
    url = "https://willcall-api.imsmetals.com"
    // url = "https://will-call-api.imsmetals.com";
    // url = "https://56z6gp3ay5.execute-api.us-east-2.amazonaws.com/";
  }
  return url;
};

export const API_VERSION = "v1/";

const instance = axios.create({
  baseURL: BASE_URL(),
});

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwicHJvamVjdCI6IndpbGwtY2FsbC1zZXJ2aWNlcyIsImlhdCI6MTUxNjIzOTAyMn0.fjmOYzFMNVW7HPfH1svQ_wBH7w6K7jV3MwfIFU1BAcE";

instance.defaults.headers.common["x-auth-header"] = token;

export default instance;
