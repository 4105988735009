import React from 'react'
import Spinner from 'react-spinkit'

import './SpinnerLoader.css'

const spinnerLoader = props => {
    return (
        <Spinner className={`spinner_loading ${props.className}`} name='double-bounce' />
    )
}

export default spinnerLoader