export const PASSWORD_MIN_LEN = 6;
export const URL_REGEXP = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const EMAIL_REGEXP =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
// export const PhNoPattern = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const URL = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // fragment locator
export const PhNoPattern =
  /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;

export const routes = {
  ROOT: "/",
  PAGE_NOT_FOUND: "/404",
  FEEDBACK: "/:OrderNumber/:BranchID/:type/:orderID?",
  THANK_YOU: "/thank-you",
};

export const OrderViewTypes = {
  LIST_VIEW: "ListView",
  GRID_VIEW: "GridView",
};

export const MinImageResolution = {
  height: 683,
  width: 1024,
};

export const formInputErrorStyle = {
  borderColor: "rgb(240, 77, 83)",
  borderLeftWidth: "5px",
  borderLeftStyle: "solid",
};

export const formInputTextErrorStyle = {
  color: "rgb(221, 39, 38)",
  fontSize: "13px",
};

export const OrderStatus = {
  PICK_CONFIRMED: {
    title: "Pick Confirmed",
    key: "pick_confirmed",
    apiKey: 6,
    socket_key: "Ready_For_Pickup_Orders",
  },
  CUSTOMER_ARRIVED: {
    title: "Customer Arrived",
    key: "customer_arrived",
    apiKey: 21,
    socket_key: "Arrived_Order",
  },
  INVOICED: {
    title: "Invoiced",
    key: "invoiced",
    apiKey: 7,
    alternativeApiKey: 8,
    socket_key: "Delivered_Orders",
  },
};

export const NotificationCategories = {
  Ready: "Ready",
  Confirm: "Confirm",
  Reminder: "Reminder",
};

export const LogsType = {
  AUDIT_LOGS: "AUDIT_LOGS",
  NOTIFICATION_LOGS: "NOTIFICATION_LOGS",
  ORDER_LOGS: "ORDER_LOGS",
};

export const ViewOrderLimitPerPage = 40;

export const StarRatingTypes = {
  customIconStarRating: "customIconStarRate",
  halfStarRating: "halfIconStarRate",
  nonRatableStarRating: "nonRatableStarRating",
  defaultIconStarRating: "defaultIconStarRating",
  halfStarNonRatable: "halfStarNonRatable",
};

export const DateFilterTypes = {
  TODAY: "today",
  MONTH: "month",
  YEAR: "year",
  WEEK: "week",
};

export const AllWeekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const FormatedWeekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const ServiceProviderHeadersTabs = [
  { title: "Dashboard", route: routes.DASHBOARD },
  { title: "Services", route: routes.SERVICES },
  { title: "Bookings", route: routes.BOOKING },
  { title: "Availability", route: routes.AVAILABILITY },
  { title: "Messages", route: routes.MESSAGE },
  { title: "Payout", route: routes.PAYOUT },
  { title: "Help", route: routes.HELP },
];

export const UserRoles = {
  associate: "associate",
  admin: "superadmin",
  manager: "manager",
  branchadmin: "admin",
};

export const NotificationSettingsType = {
  REMINDER_NOTIFICATION_STATUS: "REMINDER_NOTIFICATION_STATUS",
  READY_NOTIFICATION_STATUS: "READY_NOTIFICATION_STATUS",
  CONFIRM_NOTIFICATION_STATUS: "CONFIRM_NOTIFICATION_STATUS",
};
